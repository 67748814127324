import {nextTick} from 'vue';
import {Router, createRouter, createWebHashHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/store.enums";
import utilsService from "@/core/services/utilsService";
import layoutConfig from "@/core/config/layoutConfig";

const titlePrefix = layoutConfig.fullName;
const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/login",
        component: () => import("@/view/layout/index.vue"),
        children: [
            {
                path: "/dashboard",
                name: "vo-dashboard",
                meta: {
                    module: 'dashboard',
                    title: titlePrefix + 'Dashboard'
                },
                component: () => import("@/modules/dashboard/index.vue"),
                children: []
            },
            {
                path: "/system",
                name: "vo-system",
                meta: {module: 'system', right: 'list', title: titlePrefix},
                component: () => import("@/modules/system/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-system-settings",
                        meta: {module: 'system', right: 'edit', title: titlePrefix + 'System Settings'},
                        component: () => import("@/modules/system/settings.vue"),
                    },
                ]
            },
                        {
                path: "/example",
                name: "vo-example",
                meta: {
                    module: 'example',
                    right: 'list',
                    title: titlePrefix + 'Example'
                },
                component: () => import("@/modules/example/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-example-list",
                        meta: {module: 'example', right: 'list', title: titlePrefix + 'Example List'},
                        component: () => import("@/modules/example/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-example-new",
                        meta: {module: 'example', right: 'create', title: titlePrefix + 'Example New'},
                        component: () => import("@/modules/example/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-example-edit",
                        meta: {module: 'example', right: 'edit', title: titlePrefix + 'Example New'},
                        component: () => import("@/modules/example/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-example-delete",
                        meta: {module: 'example', right: 'remove', title: titlePrefix + 'Example Delete'},
                        component: () => import("@/modules/example/delete.vue"),
                    },
                ]
            },
            {
                path: "/addressbook",
                name: "vo-addressbook",
                meta: {
                    module: 'addressbook',
                    right: 'list',
                    title: titlePrefix + 'Addressbook'
                },
                component: () => import("@/modules/addressbook/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-addressbook-list",
                        meta: {module: 'addressbook', right: 'list', title: titlePrefix + 'Addressbook List'},
                        component: () => import("@/modules/addressbook/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-addressbook-new",
                        meta: {module: 'addressbook', right: 'create', title: titlePrefix + 'Addressbook New'},
                        component: () => import("@/modules/addressbook/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-addressbook-edit",
                        meta: {module: 'addressbook', right: 'edit', title: titlePrefix + 'Addressbook New'},
                        component: () => import("@/modules/addressbook/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-addressbook-delete",
                        meta: {module: 'addressbook', right: 'remove', title: titlePrefix + 'Addressbook Delete'},
                        component: () => import("@/modules/addressbook/delete.vue"),
                    },
                ]
            },
            {
                path: "/blog",
                name: "vo-blog",
                meta: {
                    module: 'blog',
                    right: 'list',
                    title: titlePrefix + 'Blog'
                },
                component: () => import("@/modules/blog/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-blog-list",
                        meta: {module: 'blog', right: 'list', title: titlePrefix + 'Blog List'},
                        component: () => import("@/modules/blog/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-blog-new",
                        meta: {module: 'blog', right: 'create', title: titlePrefix + 'Blog New'},
                        component: () => import("@/modules/blog/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-blog-edit",
                        meta: {module: 'blog', right: 'edit', title: titlePrefix + 'Blog New'},
                        component: () => import("@/modules/blog/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-blog-delete",
                        meta: {module: 'blog', right: 'remove', title: titlePrefix + 'Blog Delete'},
                        component: () => import("@/modules/blog/delete.vue"),
                    },
                ]
            },
            {
                path: "/shop",
                name: "vo-shop",
                meta: {
                    module: 'shop',
                    right: 'list',
                    title: titlePrefix + 'Shop'
                },
                component: () => import("@/modules/shop/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-shop-list",
                        meta: {module: 'shop', right: 'list', title: titlePrefix + 'Shop List'},
                        component: () => import("@/modules/shop/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-shop-new",
                        meta: {module: 'shop', right: 'create', title: titlePrefix + 'Shop New'},
                        component: () => import("@/modules/shop/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-shop-edit",
                        meta: {module: 'shop', right: 'edit', title: titlePrefix + 'Shop New'},
                        component: () => import("@/modules/shop/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-shop-delete",
                        meta: {module: 'shop', right: 'remove', title: titlePrefix + 'Shop Delete'},
                        component: () => import("@/modules/shop/delete.vue"),
                    },
                ]
            },
            {
                path: "/courses",
                name: "vo-courses",
                meta: {
                    module: 'courses',
                    right: 'list',
                    title: titlePrefix + 'Courses'
                },
                component: () => import("@/modules/courses/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-courses-list",
                        meta: {module: 'courses', right: 'list', title: titlePrefix + 'Courses List'},
                        component: () => import("@/modules/courses/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-courses-new",
                        meta: {module: 'courses', right: 'create', title: titlePrefix + 'Courses New'},
                        component: () => import("@/modules/courses/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-courses-edit",
                        meta: {module: 'courses', right: 'edit', title: titlePrefix + 'Courses New'},
                        component: () => import("@/modules/courses/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-courses-delete",
                        meta: {module: 'courses', right: 'remove', title: titlePrefix + 'Courses Delete'},
                        component: () => import("@/modules/courses/delete.vue"),
                    },
                ]
            },
            {
                path: "/subscriptions",
                name: "vo-subscriptions",
                meta: {
                    module: 'subscriptions',
                    right: 'list',
                    title: titlePrefix + 'Subscriptions'
                },
                component: () => import("@/modules/subscriptions/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-subscriptions-list",
                        meta: {module: 'subscriptions', right: 'list', title: titlePrefix + 'Subscriptions List'},
                        component: () => import("@/modules/subscriptions/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-subscriptions-new",
                        meta: {module: 'subscriptions', right: 'create', title: titlePrefix + 'Subscriptions New'},
                        component: () => import("@/modules/subscriptions/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-subscriptions-edit",
                        meta: {module: 'subscriptions', right: 'edit', title: titlePrefix + 'Subscriptions New'},
                        component: () => import("@/modules/subscriptions/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-subscriptions-delete",
                        meta: {module: 'subscriptions', right: 'remove', title: titlePrefix + 'Subscriptions Delete'},
                        component: () => import("@/modules/subscriptions/delete.vue"),
                    },
                ]
            },
            {
                path: "/accounting",
                name: "vo-accounting",
                meta: {
                    module: 'accounting',
                    right: 'list',
                    title: titlePrefix + 'Accounting'
                },
                component: () => import("@/modules/accounting/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-accounting-list",
                        meta: {module: 'accounting', right: 'list', title: titlePrefix + 'Accounting List'},
                        component: () => import("@/modules/accounting/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-accounting-new",
                        meta: {module: 'accounting', right: 'create', title: titlePrefix + 'Accounting New'},
                        component: () => import("@/modules/accounting/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-accounting-edit",
                        meta: {module: 'accounting', right: 'edit', title: titlePrefix + 'Accounting New'},
                        component: () => import("@/modules/accounting/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-accounting-delete",
                        meta: {module: 'accounting', right: 'remove', title: titlePrefix + 'Accounting Delete'},
                        component: () => import("@/modules/accounting/delete.vue"),
                    },
                ]
            },
            {
                path: "/reviews",
                name: "vo-reviews",
                meta: {
                    module: 'reviews',
                    right: 'list',
                    title: titlePrefix + 'Reviews'
                },
                component: () => import("@/modules/reviews/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-reviews-list",
                        meta: {module: 'reviews', right: 'list', title: titlePrefix + 'Reviews List'},
                        component: () => import("@/modules/reviews/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-reviews-new",
                        meta: {module: 'reviews', right: 'create', title: titlePrefix + 'Reviews New'},
                        component: () => import("@/modules/reviews/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-reviews-edit",
                        meta: {module: 'reviews', right: 'edit', title: titlePrefix + 'Reviews New'},
                        component: () => import("@/modules/reviews/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-reviews-delete",
                        meta: {module: 'reviews', right: 'remove', title: titlePrefix + 'Reviews Delete'},
                        component: () => import("@/modules/reviews/delete.vue"),
                    },
                ]
            },
            {
                path: "/cms",
                name: "vo-cms",
                meta: {
                    module: 'cms',
                    right: 'list',
                    title: titlePrefix + 'Content Management'
                },
                component: () => import("@/modules/cms/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-cms-list",
                        meta: {module: 'cms', right: 'list', title: titlePrefix + 'Content Management List'},
                        component: () => import("@/modules/cms/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-cms-new",
                        meta: {module: 'cms', right: 'create', title: titlePrefix + 'Content Management New'},
                        component: () => import("@/modules/cms/new.vue"),
                    },
                    {
                        path: "(edit|copy)/:id",
                        props: true,
                        name: "vo-cms-edit",
                        meta: {module: 'cms', right: 'edit', title: titlePrefix + 'Content Management New'},
                        component: () => import("@/modules/cms/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-cms-delete",
                        meta: {module: 'cms', right: 'remove', title: titlePrefix + 'Content Management Delete'},
                        component: () => import("@/modules/cms/delete.vue"),
                    },
                    {
                        path: "blocks",
                        name: "vo-cms-blocks",
                        meta: {module: 'cms', right: 'list', title: titlePrefix + 'Content Management Blocks'},
                        component: () => import("@/modules/cms/blocks-list.vue"),
                    },
                    {
                        path: "blocks/assign",
                        name: "vo-cms-blocks-assign",
                        meta: {
                            module: 'cms',
                            right: 'edit',
                            title: titlePrefix + 'Content Management Blocks Assign'
                        },
                        component: () => import("@/modules/cms/blocks-assign.vue"),
                    },
                    {
                        path: "blocks/new",
                        name: "vo-cms-blocks-new",
                        meta: {
                            module: 'cms',
                            right: 'create',
                            title: titlePrefix + 'Content Management Block New'
                        },
                        component: () => import("@/modules/cms/blocks-new.vue"),
                    },
                    {
                        path: "blocks/(edit|copy)/:id",
                        props: true,
                        name: "vo-cms-blocks-edit",
                        meta: {
                            module: 'cms',
                            right: 'edit',
                            title: titlePrefix + 'Content Management Blocks Edit'
                        },
                        component: () => import("@/modules/cms/blocks-edit.vue"),
                    },
                    {
                        path: "blocks/delete/:id",
                        props: true,
                        name: "vo-cms-blocks-delete",
                        meta: {
                            module: 'cms',
                            right: 'remove',
                            title: titlePrefix + 'Content Management Blocks Delete'
                        },
                        component: () => import("@/modules/cms/blocks-delete.vue"),
                    },
                    {
                        path: "about",
                        name: "vo-cms-about",
                        meta: {module: 'cms', title: titlePrefix + 'About'},
                        component: () => import("@/modules/cms/about.vue")
                    },
                    {
                        path: "contact",
                        name: "vo-cms-contact",
                        meta: {module: 'cms', title: titlePrefix + 'Contact'},
                        component: () => import("@/modules/cms/contact.vue")
                    },
                    {
                        path: "faq",
                        name: "vo-cms-faq",
                        meta: {module: 'cms', title: titlePrefix + 'FAQ'},
                        component: () => import("@/modules/cms/faq.vue")
                    },
                ]
            },
            {
                path: "/navigation",
                name: "vo-navigation",
                meta: {
                    module: 'navigation',
                    right: 'list',
                    title: titlePrefix + 'Content Navigation'
                },
                component: () => import("@/modules/navigation/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-navigation-list",
                        meta: {module: 'navigation', right: 'list', title: titlePrefix + 'Content Navigation List'},
                        component: () => import("@/modules/navigation/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-navigation-new",
                        meta: {module: 'navigation', right: 'create', title: titlePrefix + 'Content Navigation New'},
                        component: () => import("@/modules/navigation/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-navigation-edit",
                        meta: {module: 'navigation', right: 'edit', title: titlePrefix + 'Content Navigation New'},
                        component: () => import("@/modules/navigation/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-navigation-delete",
                        meta: {module: 'navigation', right: 'remove', title: titlePrefix + 'Content Navigation Delete'},
                        component: () => import("@/modules/navigation/delete.vue"),
                    },
                    {
                        path: "groups",
                        name: "vo-navigation-groups",
                        meta: {module: 'navigation', right: 'list', title: titlePrefix + 'Content Navigation Groups'},
                        component: () => import("@/modules/navigation/groups-list.vue"),
                    },
                    {
                        path: "groups/assign",
                        name: "vo-navigation-groups-assign",
                        meta: {
                            module: 'navigation',
                            right: 'edit',
                            title: titlePrefix + 'Content Navigation Group Assign'
                        },
                        component: () => import("@/modules/navigation/groups-assign.vue"),
                    },
                    {
                        path: "groups/new",
                        name: "vo-navigation-groups-new",
                        meta: {
                            module: 'navigation',
                            right: 'create',
                            title: titlePrefix + 'Content Navigation Group New'
                        },
                        component: () => import("@/modules/navigation/groups-new.vue"),
                    },
                    {
                        path: "groups/edit/:id",
                        props: true,
                        name: "vo-navigation-groups-edit",
                        meta: {
                            module: 'navigation',
                            right: 'edit',
                            title: titlePrefix + 'Content Navigation Group Edit'
                        },
                        component: () => import("@/modules/navigation/groups-edit.vue"),
                    },
                    {
                        path: "groups/delete/:id",
                        props: true,
                        name: "vo-navigation-groups-delete",
                        meta: {
                            module: 'navigation',
                            right: 'remove',
                            title: titlePrefix + 'Content Navigation Group Delete'
                        },
                        component: () => import("@/modules/navigation/groups-delete.vue"),
                    },

                ]
            },
            {
                path: "/users",
                name: "vo-users",
                meta: {
                    module: 'users',
                    right: 'list',
                    title: titlePrefix + 'Users'
                },
                component: () => import("@/modules/users/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-users-list",
                        meta: {module: 'users', right: 'list', title: titlePrefix + 'Users List'},
                        component: () => import("@/modules/users/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-users-new",
                        meta: {module: 'users', right: 'create', title: titlePrefix + 'Users New'},
                        component: () => import("@/modules/users/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-users-edit",
                        meta: {module: 'users', right: 'edit', title: titlePrefix + 'Users New'},
                        component: () => import("@/modules/users/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-users-delete",
                        meta: {module: 'users', right: 'remove', title: titlePrefix + 'Users Delete'},
                        component: () => import("@/modules/users/delete.vue"),
                    }
                ]
            },
            {
                path: "/licenses",
                name: "vo-licenses",
                meta: {module: 'licenses', right: 'list', title: titlePrefix + 'Licenses'},
                component: () => import("@/modules/licenses/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-licenses-list",
                        meta: {module: 'licenses', right: 'list', title: titlePrefix + 'Licenses List'},
                        component: () => import("@/modules/licenses/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-licenses-new",
                        meta: {module: 'licenses', right: 'create', title: titlePrefix + 'Storage New'},
                        component: () => import("@/modules/licenses/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-licenses-edit",
                        meta: {module: 'licenses', right: 'edit', title: titlePrefix + 'Storage Edit'},
                        component: () => import("@/modules/licenses/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-licenses-delete",
                        meta: {module: 'licenses', right: 'remove', title: titlePrefix + 'Storage Delete'},
                        component: () => import("@/modules/licenses/delete.vue"),
                    }
                ]
            },
            {
                path: "/customers",
                name: "vo-customers",
                meta: {module: 'customers', right: 'list', title: titlePrefix + 'Customers'},
                component: () => import("@/modules/customers/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-customers-list",
                        meta: {module: 'customers', right: 'list', title: titlePrefix + 'Customers List'},
                        component: () => import("@/modules/customers/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-customers-new",
                        meta: {module: 'customers', right: 'create', title: titlePrefix + 'Customers New'},
                        component: () => import("@/modules/customers/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-customers-edit",
                        meta: {module: 'customers', right: 'edit', title: titlePrefix + 'Customers Edit'},
                        component: () => import("@/modules/customers/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-customers-delete",
                        meta: {module: 'customers', right: 'remove', title: titlePrefix + 'Customers Delete'},
                        component: () => import("@/modules/customers/delete.vue"),
                    }
                ]
            },
            {
                path: "/warehouses",
                name: "vo-warehouses",
                meta: {module: 'warehouses', right: 'list', title: titlePrefix + 'Warehouses'},
                component: () => import("@/modules/warehouses/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-warehouses-list",
                        meta: {module: 'warehouses', right: 'list', title: titlePrefix + 'Warehouses List'},
                        component: () => import("@/modules/warehouses/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-warehouses-new",
                        meta: {module: 'warehouses', right: 'create', title: titlePrefix + 'Warehouses New'},
                        component: () => import("@/modules/warehouses/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-warehouses-edit",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses New'},
                        component: () => import("@/modules/warehouses/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-warehouses-delete",
                        meta: {module: 'warehouses', right: 'remove', title: titlePrefix + 'Warehouses Delete'},
                        component: () => import("@/modules/warehouses/delete.vue"),
                    },
                    {
                        path: ":warehouseId/locations",
                        props: true,
                        name: "vo-warehouses-locations",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses Locations'},
                        component: () => import("@/modules/warehouses/locations.vue"),
                    },
                    {
                        path: ":warehouseId/location/edit/:locationId",
                        props: true,
                        name: "vo-warehouses-location-edit",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses Location Edit'},
                        component: () => import("@/modules/warehouses/location-edit.vue"),
                    },
                    {
                        path: ":warehouseId/location/new",
                        props: true,
                        name: "vo-warehouses-location-new",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses Location New'},
                        component: () => import("@/modules/warehouses/location-new.vue"),
                    },
                    {
                        path: ":warehouseId/packagings",
                        props: true,
                        name: "vo-warehouses-packagings",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses Packaging'},
                        component: () => import("@/modules/warehouses/packagings.vue"),
                    },
                    {
                        path: ":warehouseId/packaging/edit/:packagingId",
                        props: true,
                        name: "vo-warehouses-packaging-edit",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses Packaging Edit'},
                        component: () => import("@/modules/warehouses/packaging-edit.vue"),
                    },
                    {
                        path: ":warehouseId/packaging/new",
                        props: true,
                        name: "vo-warehouses-packaging-new",
                        meta: {module: 'warehouses', right: 'edit', title: titlePrefix + 'Warehouses Packaging New'},
                        component: () => import("@/modules/warehouses/packaging-new.vue"),
                    },
                    {
                        path: "reports",
                        name: "vo-warehouses-reports",
                        meta: {module: 'warehouses', right: 'report', title: titlePrefix + 'Warehouses Reports'},
                        component: () => import("@/modules/warehouses/reports.vue"),
                    },
                    {
                        path: "print/:type/:id",
                        props: true,
                        name: "vo-warehouses-print",
                        meta: {module: 'warehouses', right: 'list', title: titlePrefix + 'Print'},
                        component: () => import("@/modules/warehouses/print.vue"),
                    }
                ]
            },
            {
                path: "/connectivity",
                name: "vo-connectivity",
                meta: {module: 'connectivity', right: 'list', title: titlePrefix},
                component: () => import("@/modules/connectivity/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-connectivity-list",
                        meta: {module: 'connectivity', right: 'list', title: titlePrefix + 'Connectivity List'},
                        component: () => import("@/modules/connectivity/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-connectivity-new",
                        meta: {module: 'connectivity', right: 'create', title: titlePrefix + 'Connectivity New'},
                        component: () => import("@/modules/connectivity/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-connectivity-edit",
                        meta: {module: 'connectivity', right: 'edit', title: titlePrefix + 'Connectivity Edit'},
                        component: () => import("@/modules/connectivity/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-connectivity-delete",
                        meta: {module: 'connectivity', right: 'remove', title: titlePrefix + 'Connectivity Delete'},
                        component: () => import("@/modules/connectivity/delete.vue"),
                    }
                ]
            },
            {
                path: "/items",
                name: "vo-items",
                meta: {module: 'items', right: 'list', title: titlePrefix},
                component: () => import("@/modules/items/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-items-list",
                        meta: {module: 'items', right: 'list', title: titlePrefix + 'Items List'},
                        component: () => import("@/modules/items/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-items-new",
                        meta: {module: 'items', right: 'create', title: titlePrefix + 'Items New'},
                        component: () => import("@/modules/items/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-items-edit",
                        meta: {module: 'items', right: 'edit', title: titlePrefix + 'Items Edit'},
                        component: () => import("@/modules/items/edit.vue"),
                    },
                    {
                        path: "copy/:id",
                        props: route => ({id: route.params.id, copy: true}),
                        name: "vo-items-copy",
                        meta: {module: 'items', right: 'edit', title: titlePrefix + 'Item Copy'},
                        component: () => import("@/modules/items/edit.vue"),
                    },
                    {
                        path: "variants/:id",
                        props: true,
                        name: "vo-items-variants",
                        meta: {module: 'items', right: 'edit', title: titlePrefix + 'Items Variants'},
                        component: () => import("@/modules/items/variants.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-items-delete",
                        meta: {module: 'items', right: 'remove', title: titlePrefix + 'Items Delete'},
                        component: () => import("@/modules/items/delete.vue"),
                    },
                    {
                        path: "print/:type/:id",
                        props: true,
                        name: "vo-items-print",
                        meta: {module: 'items', right: 'list', title: titlePrefix + 'Print'},
                        component: () => import("@/modules/items/print.vue"),
                    }
                ]
            },
            {
                path: "/receiving",
                name: "vo-receiving",
                meta: {module: 'receiving', right: 'list', title: titlePrefix},
                component: () => import("@/modules/receiving/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-receiving-list",
                        meta: {module: 'receiving', right: 'list', title: titlePrefix + 'Receiving List'},
                        component: () => import("@/modules/receiving/list.vue"),
                    },
                    {
                        path: "new/:type",
                        props: true,
                        name: "vo-receiving-new",
                        meta: {module: 'receiving', right: 'create', title: titlePrefix + 'Receiving New'},
                        component: () => import("@/modules/receiving/new.vue"),
                    },
                    {
                        path: "convert/:id/:convert",
                        props: true,
                        name: "vo-receiving-convert",
                        meta: {module: 'receiving', right: 'edit', title: titlePrefix + 'Receiving Convert'},
                        component: () => import("@/modules/receiving/convert.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-receiving-edit",
                        meta: {module: 'receiving', right: 'edit', title: titlePrefix + 'Receiving Edit'},
                        component: () => import("@/modules/receiving/edit.vue"),
                    },
                    {
                        path: "confirm/:id",
                        props: true,
                        name: "vo-receiving-confirm",
                        meta: {module: 'receiving', right: 'edit', title: titlePrefix + 'Receiving Confirm'},
                        component: () => import("@/modules/receiving/confirm.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-receiving-delete",
                        meta: {module: 'receiving', right: 'remove', title: titlePrefix + 'Receiving Delete'},
                        component: () => import("@/modules/receiving/delete.vue"),
                    },
                    {
                        path: "print/:type/:id",
                        props: true,
                        name: "vo-receiving-print",
                        meta: {module: 'receiving', right: 'list', title: titlePrefix + 'Print'},
                        component: () => import("@/modules/receiving/print.vue"),
                    },
                ]
            },
            {
                path: "/orders",
                name: "vo-orders",
                meta: {module: 'orders', right: 'list', title: titlePrefix},
                component: () => import("@/modules/orders/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-orders-list",
                        meta: {module: 'orders', right: 'list', title: titlePrefix + 'Orders List'},
                        component: () => import("@/modules/orders/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-orders-new",
                        meta: {module: 'orders', right: 'create', title: titlePrefix + 'Orders New'},
                        component: () => import("@/modules/orders/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-orders-edit",
                        meta: {module: 'orders', right: 'edit', title: titlePrefix + 'Orders Edit'},
                        component: () => import("@/modules/orders/edit.vue"),
                    },
                    {
                        path: "confirm-conflict/:id",
                        props: true,
                        name: "vo-orders-confirm-conflict",
                        meta: {module: 'orders', right: 'edit', title: titlePrefix + 'Orders Confirm Conflict'},
                        component: () => import("@/modules/orders/confirm-conflict.vue"),
                    },
                    {
                        path: "confirm-picklist/:id",
                        props: true,
                        name: "vo-orders-confirm-picklist",
                        meta: {module: 'orders', right: 'edit', title: titlePrefix + 'Orders Confirm Picklist'},
                        component: () => import("@/modules/orders/confirm-picklist.vue"),
                    },
                    {
                        path: "print/:type/:id",
                        props: true,
                        name: "vo-orders-print",
                        meta: {module: 'orders', right: 'list', title: titlePrefix + 'Print'},
                        component: () => import("@/modules/orders/print.vue"),
                    },
                ]
            },
            {
                path: "/shipment",
                name: "vo-shipment",
                meta: {module: 'shipment', right: 'list', title: titlePrefix},
                component: () => import("@/modules/shipment/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-shipment-list",
                        meta: {module: 'shipment', right: 'list', title: titlePrefix + 'Shipment List'},
                        component: () => import("@/modules/shipment/list.vue"),
                    },
                    {
                        path: "confirm/:id",
                        props: true,
                        name: "vo-shipment-confirm",
                        meta: {module: 'shipment', right: 'edit', title: titlePrefix + 'Shipment Confirm'},
                        component: () => import("@/modules/shipment/confirm.vue"),
                    },
                    {
                        path: "print/:type/:id",
                        props: true,
                        name: "vo-shipment-print",
                        meta: {module: 'shipment', right: 'list', title: titlePrefix + 'Print'},
                        component: () => import("@/modules/shipment/print.vue"),
                    },
                ]
            },
            {
                path: "/inventory",
                name: "vo-inventory",
                meta: {module: 'inventory', right: 'list', title: titlePrefix},
                component: () => import("@/modules/inventory/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-inventory-list",
                        meta: {module: 'inventory', right: 'list', title: titlePrefix + ' Inventory List'},
                        component: () => import("@/modules/inventory/list.vue"),
                    },
                    {
                        path: "new",
                        name: "vo-inventory-new",
                        meta: {module: 'inventory', right: 'create', title: titlePrefix + 'Inventory New'},
                        component: () => import("@/modules/inventory/new.vue"),
                    },
                    {
                        path: "edit/:id",
                        props: true,
                        name: "vo-inventory-edit",
                        meta: {module: 'inventory', right: 'edit', title: titlePrefix + ' Inventory Edit'},
                        component: () => import("@/modules/inventory/edit.vue"),
                    },
                    {
                        path: "delete/:id",
                        props: true,
                        name: "vo-inventory-delete",
                        meta: {module: 'inventory', right: 'remove', title: titlePrefix + ' Inventory Delete'},
                        component: () => import("@/modules/inventory/delete.vue"),
                    },
                    {
                        path: "print/:type/:id",
                        props: true,
                        name: "vo-inventory-print",
                        meta: {module: 'inventory', right: 'list', title: titlePrefix + 'Print'},
                        component: () => import("@/modules/inventory/print.vue"),
                    },
                ]
            },
            {
                path: "/account",
                name: "vo-account",
                meta: {module: 'account', right: 'list', title: titlePrefix},
                component: () => import("@/modules/account/index.vue"),
                children: [
                    {
                        path: "settings",
                        name: "vo-account-settings",
                        meta: {module: 'account', title: titlePrefix + 'Account :: Settings'},
                        component: () => import("@/modules/account/settings.vue")
                    }
                ]
            },
            {
                path: "/checkout",
                name: "vo-checkout",
                meta: {module: 'checkout', title: titlePrefix},
                component: () => import("@/modules/checkout/index.vue"),
                children: [
                    {
                        path: "",
                        name: "vo-checkout-stats",
                        meta: {module: 'checkout', title: titlePrefix + ' Checkout Stats'},
                        component: () => import("@/modules/checkout/stats.vue")
                    },
                ]
            },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404"
    },
    {
        path: "/login",
        name: "vo-login",
        meta: {module: 'system', title: titlePrefix + 'Login'},
        component: () => import("@/modules/login/index.vue"),
        children: []
    },
    {
        path: "/login/token/:token/",
        name: "vo-login-token",
        props: true,
        meta: {module: 'system', title: titlePrefix + 'Login'},
        component: () => import("@/modules/login/token-login.vue")
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "vo-error",
        meta: {module: 'system', title: titlePrefix + '404'},
        component: () => import("@/modules/error/index.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {

    if (to.name === 'vo-login' || to.name === 'vo-login-token') {

        // reset config to initial state
        store.commit(Mutations.RESET_LAYOUT_CONFIG);

        return next();
    }

    // check for edit mode //
    const isEditing = store.getters.getIsEditing;
    if (isEditing) {
        if (!window.confirm("Leave without saving?")) {
            return;
        }
    }

    // manual reloading //
    if (!store.getters.isUserFetched) {

        // dispatch user data also on reload page needed //
        store.dispatch(Actions.FETCH_USER).then(() => next()).catch(() => {

            store.dispatch(Actions.LOGOUT).then();
            router.push({name: "vo-login"}).then();

        });

    } else {

        next();

    }

});

router.beforeResolve((to, from, next) => {

    // check for route authentication //
    store.dispatch(Actions.CHECK_AUTH, to).then(() => {

        // @ts-ignore
        document.title = to.meta.title || '';

        // Scroll page to top on every route change
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        next();

    }).catch(() => {

        const headline = 'You do not have the necessary access rights!';
        const button = 'Oh, ok!';
        utilsService.showErrorOverlay(headline, button);

        return false;

    });

});

router.afterEach((to, from) => {

    nextTick(() => {

        if (to.name !== 'vo-login' && to.name !== 'vo-login-token') {

            // set current last call from route to history stack //
            store.dispatch(Actions.ADD_ROUTE_HISTORY, {...to});

        }

    }).then(() => {


    });

});

export default router;
