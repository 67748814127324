enum SystemActions {
    GET_SETTINGS = "systemFetchSettings",
    UPDATE_SETTINGS= "systemUpdateSettings",
}

enum SystemMutation {
    SET_RENDER_UPDATE = "systemListRenderNeeded",
    SET_LIST_RESULT = "systemListResult"
}

export {SystemActions, SystemMutation};
